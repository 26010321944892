import { appFetch, makeUri, } from '@boluo/api';
import { atom } from 'jotai';
import { store } from '@boluo/store';
export const backendUrlAtom = atom(process?.env?.PUBLIC_BACKEND_URL ?? '');
export const apiUrlAtom = atom((get) => {
    const url = get(backendUrlAtom).trim() || '';
    if (url === '') {
        return process?.env?.PUBLIC_BACKEND_URL ?? window.location.origin + '/api';
    }
    else if (url.endsWith('/api')) {
        return url;
    }
    else if (url.endsWith('/')) {
        return url + 'api';
    }
    else {
        return url + '/api';
    }
});
const TOKEN_KEY = 'BOLUO_TOKEN_V1';
export function getToken() {
    return localStorage.getItem(TOKEN_KEY) || null;
}
function addToken(params) {
    const token = getToken();
    if (!token) {
        return params;
    }
    const headers = new Headers(params.headers || {});
    headers.set('Authorization', token);
    // headers.set('Authorization', `Bearer ${token}`);
    return { ...params, headers };
}
export function setToken(token) {
    if (typeof token !== 'string') {
        return;
    }
    localStorage.setItem(TOKEN_KEY, token);
}
export async function get(path, query, apiUrl) {
    const baseUrl = apiUrl || store.get(apiUrlAtom);
    const url = makeUri(baseUrl, path, query);
    const params = addToken({ credentials: 'include' });
    return appFetch(url, params);
}
const headers = new Headers({
    'Content-Type': 'application/json',
});
export async function post(path, query, payload) {
    const baseUrl = store.get(apiUrlAtom);
    const url = makeUri(baseUrl, path, query);
    const params = addToken({
        credentials: 'include',
        headers,
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify(payload),
    });
    return appFetch(url, params);
}
export async function login(username, password) {
    const domain = process?.env?.DOMAIN;
    let withToken = true;
    if (domain && (location.hostname === domain || location.hostname.endsWith('.' + domain))) {
        withToken = false;
    }
    const result = await post('/users/login', null, { password, username, withToken });
    if (result.isOk && result.some.token) {
        setToken(result.some.token);
    }
    return result;
}
export async function patch(path, query, payload) {
    const baseUrl = store.get(apiUrlAtom);
    const url = makeUri(baseUrl, path, query);
    const params = addToken({
        credentials: 'include',
        headers,
        cache: 'no-cache',
        method: 'PATCH',
        body: JSON.stringify(payload),
    });
    return appFetch(url, params);
}
export function mediaUrl(id, download = false) {
    const baseUrl = store.get(apiUrlAtom);
    return makeUri(baseUrl, '/media/get', { download, id });
}
export function mediaHead(id) {
    const baseUrl = store.get(apiUrlAtom);
    const url = makeUri(baseUrl, '/media/get', { id });
    return fetch(url, addToken({
        method: 'HEAD',
        credentials: 'include',
    }));
}
export function upload(file, filename, mimeType, path = '/media/upload') {
    const baseUrl = store.get(apiUrlAtom);
    const url = makeUri(baseUrl, path, { filename, mimeType });
    const params = addToken({
        credentials: 'include',
        headers,
        cache: 'no-cache',
        method: 'POST',
        body: file,
    });
    return appFetch(url, params);
}
export function editAvatar(file) {
    const baseUrl = store.get(apiUrlAtom);
    const path = '/users/edit_avatar';
    const url = makeUri(baseUrl, path, { filename: file.name, mimeType: file.type });
    const params = addToken({
        credentials: 'include',
        headers,
        cache: 'no-cache',
        method: 'POST',
        body: file,
    });
    return appFetch(url, params);
}
