/*
Modified from https://github.com/vultix/ts-results
*/
var Err = /** @class */ (function () {
    function Err(err) {
        this.err = err;
        this.isOk = false;
        this.isErr = true;
    }
    Err.prototype.ok = function () {
        return null;
    };
    Err.prototype.expect = function (msg) {
        console.error(msg);
        throw this.err;
    };
    Err.prototype.unwrap = function () {
        console.error('Unhandled error', this.err);
        throw this.err;
    };
    Err.prototype.unwrapOr = function (other) {
        return other;
    };
    Err.prototype.unwrapOrElse = function (orElse) {
        return orElse(this.err);
    };
    Err.prototype.map = function () {
        return this;
    };
    Err.prototype.mapErr = function (mapper) {
        return new Err(mapper(this.err));
    };
    Err.prototype.andThen = function () {
        return this;
    };
    return Err;
}());
export { Err };
var Ok = /** @class */ (function () {
    function Ok(some) {
        this.some = some;
        this.isOk = true;
        this.isErr = false;
    }
    Ok.prototype.expect = function () {
        return this.some;
    };
    Ok.prototype.unwrap = function () {
        return this.some;
    };
    Ok.prototype.unwrapOr = function (other) {
        return this.some;
    };
    Ok.prototype.unwrapOrElse = function () {
        return this.some;
    };
    Ok.prototype.map = function (mapper) {
        return new Ok(mapper(this.some));
    };
    Ok.prototype.mapErr = function () {
        return this;
    };
    Ok.prototype.andThen = function (f) {
        return f(this.some);
    };
    return Ok;
}());
export { Ok };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var unwrap = function (result) {
    if (result.isOk) {
        return result.some;
    }
    else {
        return result.unwrap();
    }
};
export var expect = function (msg) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return function (result) {
        if (result.isOk) {
            return result.some;
        }
        else {
            return result.expect(msg);
        }
    };
};
