export var stopPropagation = function (e) {
    e.stopPropagation();
};
export function getOS() {
    if (typeof window === 'undefined')
        return 'SSR';
    var userAgent = window.navigator.userAgent.toLowerCase(), macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i, windowsPlatforms = /(win32|win64|windows|wince)/i, iosPlatforms = /(iphone|ipad|ipod)/i;
    var os = null;
    if (macosPlatforms.test(userAgent)) {
        // Fuck Apple
        // https://stackoverflow.com/a/57924983
        if (navigator.maxTouchPoints > 1) {
            os = 'iOS';
        }
        else {
            os = 'macOS';
        }
    }
    else if (iosPlatforms.test(userAgent)) {
        os = 'iOS';
    }
    else if (windowsPlatforms.test(userAgent)) {
        os = 'Windows';
    }
    else if (userAgent.includes('android')) {
        os = 'Android';
    }
    else if (!os && userAgent.includes('linux')) {
        os = 'Linux';
    }
    return os;
}
export function isApple() {
    var os = getOS();
    return os === 'macOS' || os === 'iOS';
}
