export const makeUri = (baseUrl, path, query) => {
    path = baseUrl + path;
    if (query === undefined || query === null || typeof query !== 'object') {
        return path;
    }
    const entities = Object.entries(query);
    if (entities.length === 0) {
        return path;
    }
    const searchParams = new URLSearchParams();
    for (const entry of entities) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const [key, value] = entry;
        if (typeof value === 'boolean' || typeof value === 'number' || typeof value === 'string') {
            searchParams.set(key, String(value));
        }
    }
    return `${path}?${searchParams.toString()}`;
};
export function isAppResponse(data) {
    if (typeof data !== 'object' || data === undefined || data === null) {
        return false;
    }
    return 'isOk' in data;
}
