import { Err, Ok } from '@boluo/utils';
import { isAppResponse } from './request';
export async function appFetch(url, params) {
    let result;
    if (process.env.NODE_ENV === 'development') {
        if (!params.headers) {
            params.headers = new Headers({ 'X-Debug': 'true' });
        }
        else {
            const headers = new Headers(params.headers);
            headers.set('X-Debug', 'true');
            params.headers = headers;
        }
    }
    try {
        const response = await fetch(url, params);
        let data;
        try {
            data = await response.json();
        }
        catch (cause) {
            const error = { code: 'NOT_JSON', cause };
            result = new Err(error);
        }
        if (isAppResponse(data)) {
            if (data.isOk) {
                result = new Ok(data.ok);
            }
            else {
                result = new Err(data.err);
            }
        }
        else {
            const error = { code: 'UNEXPECTED', message: 'Got incorrect data from the server' };
            result = new Err(error);
        }
    }
    catch (cause) {
        const error = { code: 'FETCH_FAIL', cause };
        result = new Err(error);
    }
    return result;
}
